<template>
  <div
    style="height: inherit"
  >
    <!-- View header -->
    <b-card
      no-body
      class="p-2"
    >
      <b-row class="align-items-center justify-content-between">
        <b-col
          sm="12"
          md="6"
        >
          <h5 class="m-0">
            Tournament participants
          </h5>
        </b-col>
        <b-col
          sm="12"
          md="6"
          class="d-flex justify-content-end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search participants ..."
              size="lg"
              type="search"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                height="20px"
                icon="SearchIcon"
                width="20px"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- /View header -->

    <b-row
      v-if="loading"
      class="text-center p-2"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <!-- Joint tournament card -->
      <b-row
        v-if="!loading"
        class="m-0"
      >
        <!-- No participants  -->
        <b-col
          v-if="!loading && (!participants || participants?.length === 0)"
        >
          <div class="d-flex justify-content-center flex-column">
            <div class="font-italic m-2 d-flex justify-content-center">
              <feather-icon
                icon="InfoIcon"
                size="40"
              />
            </div>
            <div class="font-italic d-flex justify-content-center">
              No participants.
            </div>
          </div>
        </b-col>

        <!-- Participants list -->

        <b-col
          v-for="participant in participants"
          :key="participant.id"
          class="d-flex mb-1"
          md="6"
          sm="12"
          lg="4"
        >
          <organizer-participant-card
            :key="participant.id"
            :participant="participant"
            :tournament="tournament"
            @dashboardTournamentParticipantRemoved="fetchTournament"
            @dashboardTournamentParticipantCheckedIn="fetchTournament"
          />
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

  </div>
</template>
<script>
import {
  BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BRow, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import OrganizerParticipantCard from '@/views/dashboard/tournament/OrganizerParticipantCard.vue'

export default {
  components: {
    OrganizerParticipantCard,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VuePerfectScrollbar,
  },
  mixins: [
    breadcrumbManager,
  ],
  data() {
    return {
      selectedParticipant: {},
      isCancelingRegistration: false,
      loading: false,
      participants: [],
      searchQuery: '',
      perfectScrollbarSettings: {
        suppressScrollX: true,
      },
      tournament: null,
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  watch: {
    searchQuery(searchValue) {
      if (!searchValue) {
        this.participants = this.fetchTournament()
      }

      this.participants = this.tournament?.participants.filter(
        participant => {
          if (!participant.participant) {
            return participant.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          }
          return participant.participant.name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        },
      )
    },

  },
  async created() {
    await this.fetchTournament()
  },
  methods: {
    async fetchTournament() {
      this.loading = true
      // fetch tournament
      const { data } = await this.$api.tournament.fetchMyTournamentById(
        Number(this.$route.params.id),
        Number(this.$route.params.tournamentId),
      )
      this.loading = false
      this.tournament = data

      this.participants = data.participants
    },
  },
}
</script>

<style lang="scss">
.scroll-area {
  height: calc(100% - 115px);
}
</style>
