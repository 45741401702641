var render = function () {
  var _vm$participant, _vm$participant$parti, _vm$participant2, _vm$tournament, _vm$participant3, _vm$participant4, _vm$tournament2, _vm$participant5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "p-2 h-100 w-100 m-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$participant = _vm.participant) === null || _vm$participant === void 0 ? void 0 : (_vm$participant$parti = _vm$participant.participant) === null || _vm$participant$parti === void 0 ? void 0 : _vm$participant$parti.avatar_image,
      "variant": "light-primary",
      "size": "100"
    }
  }), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s((_vm$participant2 = _vm.participant) !== null && _vm$participant2 !== void 0 && _vm$participant2.participant ? _vm.participant.participant.name : _vm.participant.name) + " ")])], 1)], 1), (_vm$tournament = _vm.tournament) !== null && _vm$tournament !== void 0 && _vm$tournament.enable_checkin ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [(_vm$participant3 = _vm.participant) !== null && _vm$participant3 !== void 0 && _vm$participant3.checked_in ? _c('b-badge', {
    staticClass: "lineup-badge mb-1",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Checked In ")]) : _c('b-badge', {
    staticClass: "lineup-badge mb-1",
    attrs: {
      "variant": "light-secondary"
    }
  }, [_vm._v(" Not Checked In yet ")])], 1)], 1) : _vm._e(), _c('b-row', {}, [_c('b-col', {
    staticClass: "text-center"
  }, [((_vm$participant4 = _vm.participant) === null || _vm$participant4 === void 0 ? void 0 : _vm$participant4.participant) !== null ? _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleProfileClick
    }
  }, [_vm._v(" Profile ")]) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": _vm.handleRemoveParticipantClick
    }
  }, [_vm._v(" Remove ")]), (_vm$tournament2 = _vm.tournament) !== null && _vm$tournament2 !== void 0 && _vm$tournament2.enable_checkin && !((_vm$participant5 = _vm.participant) !== null && _vm$participant5 !== void 0 && _vm$participant5.checked_in) ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm",
      "variant": "warning"
    },
    on: {
      "click": _vm.handleCheckinParticipantClick
    }
  }, [_vm.checkingIn ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Checking in..."
    }
  }) : _c('span', [_vm._v("Checkin")])], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }