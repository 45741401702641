<template>
  <b-card
    no-body
    class="p-2 h-100 w-100 m-0"
  >
    <b-row>
      <b-col class="d-flex flex-column align-items-center">
        <b-avatar
          :src="participant?.participant?.avatar_image"
          :variant="`light-primary`"
          class="mb-2"
          size="100"
        />
        <h4 class="mb-2">
          {{
              participant?.participant
                  ? participant.participant.name
                  : participant.name
          }}
        </h4>
      </b-col>
    </b-row>

    <b-row v-if="tournament?.enable_checkin">
      <b-col class="d-flex flex-column align-items-center">
        <b-badge
          v-if="participant?.checked_in"
          variant="light-success"
          class="lineup-badge mb-1"
        >
          Checked In
        </b-badge>
        <b-badge
          v-else
          variant="light-secondary"
          class="lineup-badge mb-1"
        >
          Not Checked In yet
        </b-badge>
      </b-col>
    </b-row>

    <!-- profile btn -->
    <b-row class="">
      <b-col class="text-center">
        <b-button
          v-if="participant?.participant !== null"
          size="sm"
          variant="primary"
          @click="handleProfileClick"
        >
          Profile
        </b-button>
      </b-col>
    </b-row>

    <!-- edit lineup btn -->
    <b-row
      class="mt-1"
    >
      <b-col class="text-center">
        <b-button
          size="sm"
          variant="danger"
          @click="handleRemoveParticipantClick"
        >
          Remove
        </b-button>

        <b-button
          v-if="tournament?.enable_checkin && !participant?.checked_in"
          size="sm"
          class="ml-1"
          variant="warning"
          @click="handleCheckinParticipantClick"
        >
          <b-spinner
            v-if="checkingIn"
            small
            label="Checking in..."
          />
          <span v-else>Checkin</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { MemberRoleType } from '@/constants/MemberRoleType'
import TournamentStatusType from '@/constants/TournamentStatusType'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BAvatar,
    BBadge,
    BSpinner,
  },
  props: {
    participant: {
      type: Object,
      default: null,
    },
    tournament: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      checkingIn: false,
      removing: false,
      MemberRoleType,
      TournamentParticipantType,
      TournamentStatusType,
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  methods: {
    handleRemoveParticipantClick() {
      this.$swal({
        title: `Remove ${this.participant?.participant
          ? this.participant.participant.name
          : this.participant.name}?`,
        text: `Are you sure you want to remove ${this.participant?.participant
          ? this.participant.participant.name
          : this.participant.name} from tournament?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        cancelButtonText: 'Close',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const { success } = await this.$api.tournament.deleteParticipant(this.participant.id)

            if (success) {
              this.$emit('dashboardTournamentParticipantRemoved')
            }
          }
        })
    },
    async handleCheckinParticipantClick() {
      this.checkingIn = true
      await this.$api.tournament.checkinParticipant(this.tournament.id, this.participant.participant_id)
      this.checkingIn = false

      this.$emit('dashboardTournamentParticipantCheckedIn')
    },
    handleProfileClick() {
      if (!this.participant?.participant_id) {
        return
      }

      this.$router.push({
        name:
            this.participant.participant_type === TournamentParticipantType.TEAM
              ? 'team-profile'
              : 'user-profile',
        params: { id: this.participant.participant_id },
      })
    },
  },
}
</script>

<style lang="scss">
.lineup-badge {
  padding: 6px 8px;
}
</style>
